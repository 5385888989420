.pr-chart{
    width: 100%;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
}

canvas {
    margin: 0 auto;
}


.pr-chart-header {
    font-family: "Google Sans Regular"
}

@media (max-width:768px){

    .pr-chart-header {
        font-size: 20px;
    }
}